import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

const DateTimePicker = (props) => {
  const {
    name,
    id,
    labelDate,
    labelTime,
    errorClassName,
    errorMessage,
    disabled,
    required,
    defaultTime,
  } = props;

  const parsedDateTime = DateTime.fromISO(props.value);
  const initialValue = parsedDateTime.isValid ? value : "";
  const initialDate = parsedDateTime.isValid
    ? parsedDateTime.toFormat("yyyy-LL-dd")
    : "";
  const initialTime = parsedDateTime.isValid
    ? parsedDateTime.toFormat("HH:mm:ss")
    : defaultTime;

  const [value, setValue] = useState(initialValue);
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const newDateTime = DateTime.fromISO(`${date}T${time}`);

    if (newDateTime.isValid) {
      setValue(newDateTime.toISO());
    }
  }, [date, time]);

  return (
    <div className="form-row p-0 m-0">
      <div className="form-group col-md-6 p-0 m-0">
        <label htmlFor="challenge_start_date">{labelDate}</label>
        <input
          type="date"
          id={`${id}-date`}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className={`form-control ${errorClassName}`}
          required={required}
          disabled={disabled}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
      <div className="form-group col-md-6 p-0 m-0">
        <label htmlFor="challenge_start_date">{labelTime}</label>
        <input
          type="time"
          id={`${id}-time`}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className={`form-control ${errorClassName}`}
          required={required}
          disabled={disabled}
        />
      </div>
      <input
        type="hidden"
        name={name}
        id={id}
        defaultValue={value}
        className="form-control"
        required={required}
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  labelDate: PropTypes.string.isRequired,
  labelTime: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  defaultTime: PropTypes.string,
};

DateTimePicker.defaultProps = {
  value: null,
  errorClassName: null,
  errorMessage: null,
  disabled: false,
  required: false,
  defaultTime: "00:00",
};

export default DateTimePicker;
