import React, { useState } from "react";
import PropTypes from "prop-types";
import { PORTFOLIO_PROP_TYPES } from "./constants/prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Portfolios = (props) => {
  const { portfolios } = props;

  const [copied, setCopied] = useState({});

  const copyLinkToClipboard = async (id, url) => {
    setCopied({ ...copied, [id]: true });
    await navigator.clipboard.writeText(url);

    setTimeout(() => {
      setCopied({ ...copied, [id]: null });
    }, 1000);
  };

  return (
    <div className="portfolio-deck">
      {portfolios.length === 0 ? (
        <p className="mt-2">
          You not not have any portfolios. Use the button above to create one!
        </p>
      ) : (
        portfolios.map(
          ({
            id,
            affiliation,
            published,
            editPath,
            checkoutStartPath,
            publicUrl,
          }) => (
            <div
              className="card mb-3 mt-3 portfolio-card"
              key={`portfolio-${id}`}
            >
              <div className="card-body d-flex flex-row align-items-center">
                <div className="flex-grow-1">
                  <div className="font-weight-bold title">
                    <a href={publicUrl}>{affiliation.name}</a>
                  </div>
                  {publicUrl && published && (
                    <button
                      type="button"
                      className="copy-link"
                      onClick={() => copyLinkToClipboard(id, publicUrl)}
                    >
                      {copied[id] === true ? (
                        <FontAwesomeIcon icon={"clipboard-check"} />
                      ) : (
                        <FontAwesomeIcon icon={["far", "clipboard"]} />
                      )}
                      Copy Portfolio URL
                    </button>
                  )}
                </div>
                <div className="flex-grow-0 d-flex align-items-center">
                  {published ? (
                    <div className="badge badge-pill badge-success">
                      Published
                    </div>
                  ) : (
                    <div className="badge badge-pill badge-danger">
                      Unpublished
                    </div>
                  )}
                  {!published && (
                    <a
                      type="button"
                      className="btn btn-link vertical-icon-link"
                      href={checkoutStartPath}
                    >
                      <FontAwesomeIcon icon={"share-square"} />
                      Purchase & Publish
                    </a>
                  )}
                  <a
                    href={editPath}
                    className="btn btn-link vertical-icon-link"
                  >
                    <FontAwesomeIcon icon={"edit"} />
                    Edit
                  </a>
                </div>
              </div>
            </div>
          )
        )
      )}
    </div>
  );
};

Portfolios.propTypes = {
  portfolios: PropTypes.arrayOf(PropTypes.exact(PORTFOLIO_PROP_TYPES)),
};

Portfolios.defaultProps = {
  portfolios: [],
};

export default Portfolios;
